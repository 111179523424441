/* eslint-disable @typescript-eslint/no-non-null-assertion */
import HttpClient, { Response } from "./http-client";
import Partner from "@/types/partner";
import PartnerStatus from "@/types/partner-status";
import PartnerAddress from "@/types/partner-address";
import PartnerContact from "@/types/partner-contact";
import PartnerIdentifier from "@/types/partner-identifier";
import PartnerUser from "@/types/partner-user";
import PartnerContactType from "@/types/partner-contact-type";
import PartnerComission from "../types/partner-comission";
import PartnerDocument from "@/types/partner-document";
import PartnerLoanComissionBatchRegister from "@/types/partner-loan-commission-batch-register";
import PartnerLoanCommission from "@/types/partner-loan-commission";
import PartnerResponsibleCommercial from "../types/partner-responsible-commercial";
import PartnerLoanCommissionBatch from "@/types/partner-loan-commission-batch";

export default class PartnerService extends HttpClient {
  private static classInstance?: PartnerService;

  private constructor() {
    super(process.env.VUE_APP_PARTNER_URL!);
  }

  public static getInstance(): PartnerService {
    if (!this.classInstance) {
      this.classInstance = new PartnerService();
    }

    return this.classInstance;
  }

  public async listPartners(
    filters: PartnerFilters
  ): Promise<Response<PartnerList>> {
    const params = {
      page: filters.page || 1,
      limit: filters.limit || 10
    } as PartnerFilters;

    if (filters.name) {
      params.name = filters.name;
    }

    if (filters.document) {
      params.document = filters.document;
    }

    if (filters.status) {
      params.status = filters.status;
    }

    if (filters.subpartnerId) {
      params.subpartnerId = filters.subpartnerId;
    }

    if (filters.responsibleCommercialId) {
      params.responsibleCommercialId = filters.responsibleCommercialId;
    }

    if (filters.email) {
      params.email = decodeURIComponent(filters.email);
    }

    if (filters.loadRelations !== undefined) {
      params.loadRelations = filters.loadRelations;
    }

    return this.axiosCall<PartnerList>({
      method: "GET",
      url: `/partner`,
      params
    });
  }

  public async createPartner(
    params: CreatePartnerParams
  ): Promise<Response<void>> {
    return this.axiosCall<void>({
      method: "POST",
      url: `/partner`,
      data: {
        partner: params
      }
    });
  }

  public async updatePartner(
    partnerId: number,
    params: UpdatePartnerParams
  ): Promise<Response<void>> {
    return this.axiosCall<void>({
      method: "PATCH",
      url: `/partner/${partnerId}`,
      data: {
        partner: params
      }
    });
  }

  public async getPartnerCommercial(
    partnerId: number
  ): Promise<Response<PartnerResponsibleCommercial>> {
    return this.axiosCall<PartnerResponsibleCommercial>({
      method: "GET",
      url: `/partner-responsible-commercial/get-commercial-of-partner/${partnerId}`
    });
  }
  public async updateParentPartner(
    partnerId: number,
    parentPartnerId: number | null | undefined
  ): Promise<Response<void>> {
    const query = parentPartnerId ? `?parentId=${parentPartnerId}` : "";
    return this.axiosCall<void>({
      method: "PATCH",
      url: `/partner/update-parent/${partnerId}${query}`,
      data: {
        partnerId
      }
    });
  }

  public async getPartnerByUserId(userId: number): Promise<Response<Partner>> {
    return this.axiosCall<Partner>({
      method: "GET",
      url: `/partner/user/${userId}`
    });
  }

  public async getLoggedPartner(): Promise<Response<Partner>> {
    return this.axiosCall<Partner>({
      method: "GET",
      url: `/partner/get-logged-partner`
    });
  }

  public async getUserPartner(userId: number): Promise<Response<UserPartner>> {
    return this.axiosCall<UserPartner>({
      method: "GET",
      url: `/users-partners/user/${userId}`
    });
  }

  public async getPartnerTree(
    partnerId: number
  ): Promise<Response<PartnerTree>> {
    return this.axiosCall<PartnerTree>({
      method: "GET",
      url: `/partner/get-tree/${partnerId}`
    });
  }

  public async removePartner(partnerId: number): Promise<Response<Partner>> {
    return this.axiosCall<Partner>({
      method: "DELETE",
      url: `/partner/${partnerId}`
    });
  }

  public async deletePartnerAndUsers(
    partnerId: number
  ): Promise<Response<DeleteResult>> {
    return this.axiosCall<DeleteResult>({
      method: "DELETE",
      url: `/partner/delete-partner-and-users/${partnerId}`,
      data: {
        partnerId
      }
    });
  }

  public async validateDocument(
    partnerDocument: string
  ): Promise<Response<Partner>> {
    return this.axiosCall<Partner>({
      method: "GET",
      url: `/partner/check-document/${partnerDocument.replace(/\D/g, "")}`
    });
  }

  public async listPartnerStatus(): Promise<Response<PartnerStatus[]>> {
    return this.axiosCall<PartnerStatus[]>({
      method: "GET",
      url: `/partner-status`
    });
  }

  public async listPartnerContactTypes(): Promise<
    Response<PartnerContactTypeList>
  > {
    return this.axiosCall<PartnerContactTypeList>({
      method: "GET",
      url: `/partner-contact-type`
    });
  }

  public async approvePartner(
    id: number,
    comissionPercentage: string
  ): Promise<any> {
    return this.axiosCall<void>({
      method: "PATCH",
      url: `/partner/approve/${id}`,
      data: { comissionPercentage }
    });
  }

  public async refusePartner(id: number): Promise<any> {
    return this.axiosCall<void>({
      method: "PATCH",
      url: `/partner/refuse/${id}`,
      data: { id }
    });
  }

  public async uploadPartnerDocument(
    partnerId: number,
    documentTypeId: number,
    file: File
  ): Promise<Response<PartnerDocument>> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("partnerId", partnerId.toString());
    formData.append("documentTypeId", documentTypeId.toString());

    return this.axiosCall<PartnerDocument>({
      method: "POST",
      url: `/partner-document`,
      data: formData
    });
  }

  public async deletePartnerDocument(
    partnerDocumentId: number
  ): Promise<Response<PartnerDocument>> {
    return this.axiosCall<PartnerDocument>({
      method: "DELETE",
      url: `/partner-document/${partnerDocumentId}`
    });
  }

  public async partnerLoanCommissionBatchRegister(
    params: PartnerLoanCommissionBatchRegisterParams
  ): Promise<Response<PartnerLoanCommissionBatchRegisterList>> {
    return this.axiosCall<PartnerLoanCommissionBatchRegisterList>({
      method: "GET",
      url: `/partner-loan-commission-batch-register`,
      params
    });
  }

  public async partnerLoanCommissionBatch(
    params: FindAllPartnerLoanCommissionBatchParams
  ): Promise<Response<PartnerLoanCommissionBatchList>> {
    return this.axiosCall<PartnerLoanCommissionBatchList>({
      method: "GET",
      url: `/partner-loan-commission-batch`,
      params
    });
  }

  public async updatePartnerLoanCommissionBatchStatus(
    params: UpdatePartnerLoanCommissionBatchParams
  ): Promise<Response<boolean>> {
    return this.axiosCall<boolean>({
      method: "PATCH",
      url: `/partner-loan-commission-batch/update-status`,
      params,
      data: params
    });
  }

  public async partnerLoanCommissions(
    params: PartnerLoanCommissionParams
  ): Promise<Response<PartnerLoanCommissionList>> {
    return this.axiosCall<PartnerLoanCommissionList>({
      method: "GET",
      url: `/partner-loan-commission`,
      params
    });
  }

  public async exportPartnerLoanCommissions(
    params: Omit<PartnerLoanCommissionParams, "page" | "limit">
  ): Promise<Response<BlobPart>> {
    return this.axiosCall<BlobPart>({
      method: "GET",
      url: `/partner-loan-commission/export`,
      params,
      responseType: "blob"
    });
  }

  public async activeAndDeactivePartner(
    partnerId: number
  ): Promise<Response<UpdateResult>> {
    return this.axiosCall<UpdateResult>({
      method: "PATCH",
      url: `/partner/active-and-deactive/${partnerId}`,
      data: {
        partnerId
      }
    });
  }
}

export type PartnerContactTypeList = PartnerContactType[];

export interface PartnerFilters {
  name?: string;
  document?: string;
  status?: number | null;
  subpartnerId?: number | null;
  responsibleCommercialId?: number | null;
  email?: string;
  page?: number;
  limit?: number;
  loadRelations?: boolean;
}

export interface PartnerList {
  data: Partner[];
  total: number;
}
export interface CreatePartnerParams
  extends Omit<Partner, "statusId" | "active"> {
  address: PartnerAddress;
  contacts: PartnerContact[];
  identifier: PartnerIdentifier;
  user?: PartnerUser;
  comission: PartnerComission;
}
export interface UpdatePartnerParams
  extends Omit<Partner, "responsibleCommercial"> {
  address: PartnerAddress;
  contacts: PartnerContact[];
  identifier: PartnerIdentifier;
  user: PartnerUser;
  comission: PartnerComission;
  responsibleCommercial: PartnerResponsibleCommercial | null;
}

export interface PartnerContactParams {
  email: string;
  mobilePhoneWithAreaCode: string;
  typeId: number;
  phone: string;
  phoneExtension: string;
}

export interface UserPartner {
  id: number;
  userId: number;
  partnerId: number;
  partner: Partner;
  createdAt: string;
}

export interface PartnerLoanCommissionBatchRegisterParams {
  page?: number;
  limit?: number;
  partnerSearch?: string;
  batchId?: number;
  batchGenerationDateStart?: string;
  batchGenerationDateEnd?: string;
  paidDateStart?: string;
  paidDateEnd?: string;
  statusId?: number;
}

export interface UpdatePartnerLoanCommissionBatchParams {
  paidDate?: string | null;
  statusId?: number | null;
  amountPaid: number;
  id: number;
}

export interface FindAllPartnerLoanCommissionBatchParams {
  page?: number;
  limit?: number;
  partnerSearch?: string;
  id?: number;
  sort: string;
  batchGenerationDateStart?: string;
  batchGenerationDateEnd?: string;
  paidDateStart?: string;
  paidDateEnd?: string;
  statusId?: number;
}

export interface PartnerLoanCommissionBatchRegisterList {
  data: PartnerLoanComissionBatchRegister[];
  total: number;
}

export interface PartnerLoanCommissionBatchList {
  data: (PartnerLoanCommissionBatch & { totalAmount: number })[];
  total: number;
}

export interface PartnerLoanCommissionParams {
  page?: number;
  limit?: number;
  partnerId?: number;
  companyName?: string;
  companyCnpj?: string;
  loanId?: number;
  initialContractingDate?: string;
  finalContractingDate?: string;
  initialCreatedAtDate?: string;
  finalCreatedAtDate?: string;
  statusId?: number;
  companyId?: number;
}

export interface PartnerLoanCommissionList {
  data: PartnerLoanCommission[];
  total: number;
}
export interface PartnerTreeItem {
  name: string;
  document: string;
  level: number;
}

export interface UpdateResult {
  raw: any;
  affected?: number;
}

export type DeleteResult = UpdateResult;

export type PartnerTree = PartnerTreeItem[];
