
import { Vue, Component, Watch } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import User, { UserFlag, UserStatus } from "@/types/user";
import { DataOptions, DataTableHeader } from "vuetify";
import SafetyService, {
  UserFilters,
  UserList,
  UserTypeOption
} from "@/services/safety-service";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import PartnerService, { PartnerList } from "@/services/partner-service";
import UsersManageModal from "@/components/users/UsersManageModal.vue";
import debounce from "debounce";
import Modal from "@/components/Modal.vue";
import dayjs from "@/plugins/day-js";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import store from "@/store";
import { PermissionManagementList } from "@/types/user-manage-form";

@Component({
  components: {
    UsersManageModal,
    Modal,
    Page
  }
})
export default class Users extends Vue {
  UserFlag = UserFlag;
  service: SafetyService;
  companyService: CompanyService;
  partnerService: PartnerService;
  users: UserList;
  filters: UserFilters;
  headers: Array<DataTableHeader>;
  addUser = false;
  editUser: User | null = null;
  showUsers = false;
  loading = false;
  options: DataOptions;
  companies: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  partners: PartnerList = {
    data: [],
    total: 0
  };
  statusList = [
    {
      value: UserStatus.PENDING_APPROVAL,
      text: "Aguardando aprovação",
      color: "orange"
    },
    {
      value: UserStatus.REPROVED,
      text: "Reprovado",
      color: "red"
    },
    { value: UserStatus.APPROVED, text: "Aprovado", color: "blue" },
    { value: UserStatus.ACTIVE, text: "Ativo", color: "green" },
    { value: UserStatus.INACTIVE, text: "Desativado", color: "purple" }
  ];
  userFilterType?: string | null = null;
  userFiltersTypes = [
    {
      value: "cpf",
      text: "CPF"
    },
    {
      value: "email",
      text: "Email"
    },
    {
      value: "phone",
      text: "Telefone"
    }
  ];
  userLinkedType?: string | null = null;
  userFilterLinkedTypes = [
    this.hasPermissions(["LISTAR_EMPRESAS"])
      ? {
          value: "companies",
          text: "Filtrar por Empresa"
        }
      : null,
    this.hasPermissions(["LISTAR_PARCEIROS"])
      ? {
          value: "partners",
          text: "Filtrar por Parceiro"
        }
      : null
  ];
  defaultPermissionsForAdminGooroo: PermissionManagementList = [];
  defaultPermissionsForPartnerMaster: PermissionManagementList = [];
  defaultPermissionsForPartner: PermissionManagementList = [];

  constructor() {
    super();
    this.service = SafetyService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.headers = [
      { text: "Cadastro", value: "createdAt", sortable: true },
      { text: "Nome", value: "name", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Telefone", value: "phone", sortable: false },
      { text: "Tipo", value: "type", sortable: false },
      { text: "Status", value: "status", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.options = {} as DataOptions;
    this.filters = {
      page: 1,
      limit: 10,
      sort: "name:ASC",
      search: "",
      types: []
    };
    this.users = {
      items: [],
      total: 0
    };
  }

  mounted() {
    this.loadFilters();

    if (this.isAdminGooroo) {
      this.loadPermissionsFor("ADMIN_GOOROO");
      this.loadPermissionsFor("PARTNER_MASTER");
      this.loadPermissionsFor("PARTNER");
    } else if (this.isPartnerMaster) {
      this.loadPermissionsFor("PARTNER");
    }
  }

  async loadFilters(): Promise<void> {
    this.fetchCompanies();
    this.fetchPartners();
  }

  async fetchCompanies(): Promise<void> {
    const [companyError, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: -1,
      sort: "name:ASC"
    });

    if (companyError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de empresas para o filtro."
      });
    }

    if (companies) {
      this.companies = companies;
    }
  }

  async loadPermissionsFor(userType: string): Promise<void> {
    const [error, permissionsData] = await this.service.listPermissions(
      userType
    );

    if (!error) {
      const permissionList = permissionsData!.map((data, index) => {
        return {
          id: `${index}-${data.module}`,
          readableName: data.module,
          children: data.permissions.map((p) => {
            const requiredForTypes = JSON.parse(
              p.requiredForTypesJson
            ) as string[];

            return {
              ...p,
              disabled:
                requiredForTypes.includes(userType) ||
                (p.canOnlyBeAssignedIfRequestUserHasIt &&
                  !this.hasPermissions([p.name]))
            };
          })
        };
      });

      if (userType === "ADMIN_GOOROO") {
        this.defaultPermissionsForAdminGooroo = permissionList;
      } else if (userType === "PARTNER_MASTER") {
        this.defaultPermissionsForPartnerMaster = permissionList;
      } else if (userType === "PARTNER") {
        this.defaultPermissionsForPartner = permissionList;
      }
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchPartners(): Promise<void> {
    if (this.hasPermissions(["LISTAR_PARCEIROS"])) {
      const [error, partners] = await this.partnerService.listPartners({
        page: 1,
        limit: -1
      });

      if (error) {
        this.$notify({
          type: "error",
          text: "Não foi possível carregar a lista de parceiros para o filtro."
        });
      }

      if (partners) {
        this.partners = partners;
      }
    }
  }

  userFilterTypeChanged(): void {
    this.filters = {
      ...this.filters,
      cpf: undefined,
      email: undefined,
      phone: undefined
    };
    if (!this.userFilterType) {
      this.applyFilter();
    }
  }

  userLinkedToChanged(): void {
    this.filters = {
      ...this.filters,
      companyId: undefined,
      partnerId: undefined
    };
    if (!this.userLinkedType) {
      this.applyFilter();
    }
  }

  @Watch("options")
  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.fetchUsers();

    return tableOptions;
  }

  handleSearch = debounce(() => {
    this.filters.page = 1;
    this.filters.sort = "name:ASC";
    this.fetchUsers();
  }, 700);

  applyFilter() {
    this.onOptionsChange({ ...this.options, page: 1 });
  }

  async fetchUsers(): Promise<UserList> {
    this.loading = true;
    let { types, ...filters } = this.filters;

    types = types ?? [];

    if (["PARTNER_MASTER", "PARTNER"].includes(this.authenticatedUser.type)) {
      types = types.concat("PARTNER_MASTER", "PARTNER");
    }

    const [error, users] = await this.service.listUsers({
      ...filters,
      ...(types.length ? { types } : {})
    });

    if (!error && users) {
      this.users = users;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;
    return this.users;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "name";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  async handleApproveUser() {
    if (this.editUser) {
      const [error, updatedUser] = await this.service.approveUser(
        this.editUser.id!
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.users.items = this.users.items.map((item) => {
          if (item.id === this.editUser?.id) {
            item.status = updatedUser.status;
            item.flag = updatedUser.flag;
          }
          return item;
        });
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O usuário foi aprovado!"
        });
      }
    }
  }

  async handleReproveUser() {
    if (this.editUser) {
      const [error, updatedUser] = await this.service.reproveUser(
        this.editUser.id!
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.users.items = this.users.items.map((item) => {
          if (item.id === this.editUser?.id) {
            item.status = updatedUser.status;
            item.flag = updatedUser.flag;
          }
          return item;
        });
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O usuário foi reprovado!"
        });
      }
    }
  }

  async handleActivateUser() {
    if (this.editUser) {
      const [error, updatedUser] = await this.service.activateUser(
        this.editUser.id!
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.users.items = this.users.items.map((item) => {
          if (item.id === this.editUser?.id) {
            item.status = updatedUser.status;
            item.flag = updatedUser.flag;
          }
          return item;
        });
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O usuário foi ativado!"
        });
      }
    }
  }

  async handleInactivateUser() {
    if (this.editUser) {
      const [error, updatedUser] = await this.service.inactivateUser(
        this.editUser.id!
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.users.items = this.users.items.map((item) => {
          if (item.id === this.editUser?.id) {
            item.status = updatedUser.status;
            item.flag = updatedUser.flag;
          }
          return item;
        });
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O usuário foi desativado!"
        });
      }
    }
  }

  async hadleDeleteUser() {
    const userResponse = confirm(
      "Você está prestes a excluir um usuario, essa ação é ireversivel. Deseja continuar?"
    );
    if (userResponse && this.editUser) {
      const [error, deletedUser] = await this.service.deleteUser(
        this.editUser.id!
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (deletedUser) {
        this.$emit("input");
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O usuário foi deletado!"
        });
      }
    }
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  closeModal() {
    this.addUser = false;
    this.editUser = null;
  }

  get userTypes(): UserTypeOption[] {
    if (this.authenticatedUser.type === "ADMIN_GOOROO") {
      return this.service.userTypes;
    }
    if (["PARTNER_MASTER", "PARTNER"].includes(this.authenticatedUser.type)) {
      return this.service.userTypes.filter((userType) =>
        ["PARTNER_MASTER", "PARTNER"].includes(userType.value)
      );
    }
    const index = this.service.userTypes.findIndex(
      (item) => item.value === this.authenticatedUser.type
    );
    return this.service.userTypes.slice(
      index + 1,
      this.service.userTypes.length
    );
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get isPartnerMaster(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "PARTNER_MASTER"
    );
  }
}
